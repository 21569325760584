export const environment = {
  production: false,
  // API_ENDPOINT: "http://localhost:8000", // WORK LOCAL
  // API_ENDPOINT: "http://15.185.42.68:8000", // WORK SERVER
  // API_ENDPOINT: "http://15.185.175.100:8000", // TEST V3
  //API_ENDPOINT: "https://console.matajar.ae", // LIVE
  // API_ENDPOINT: "http://3.28.199.25:8000", // baqalat
  // API_ENDPOINT: 'http://109.123.252.180:8000', //baqalat WORK
  API_ENDPOINT: 'https://admin.baqalat.express', //baqalat LIVE
  // API_ENDPOINT: 'http://194.146.12.169:8000', //baqalat TEST
};
